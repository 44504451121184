<template>

    <div class="echarts_box">

        <!-- <div id='mapDom' class="echart" style="width: 100%;height: 100%;"></div> -->
        <div class="echart" id="mapDom" ref="echartsBox">
        </div>

        <div class="map_dsc">
            <div class="map_dsc_title">城市TOP10</div>
            <el-table :data="dataListDsc" class="tabledata"
                :cell-style="{ color: 'rgba(85,86,89,1)', fontSize: '12px', background: 'rgba(245, 249, 255, 1)' }"
                :header-cell-style="{ color: 'rgba(85,86,89,1)', fontSize: '12px', background: 'rgba(245, 249, 255, 1)' }">

                <el-table-column prop="name" label="省份" />
                <el-table-column prop="value" label="数量">
                    <template #default="scope">
                        <el-statistic :value-style="{ color: 'rgba(85,86,89,1)', fontSize: '12px', background: 'rgba(245, 249, 255, 1)' }"	 :value="scope.row.value" />
                    </template>
                </el-table-column>
            </el-table>
        </div>

    </div>


    <!-- <div style="width: 80%;height: 100vh;margin: 0 auto;">
        <div id='mapDom' style="width: 100%;height: 100%;"></div>
    </div> -->
</template>
<script setup>
import { ref, nextTick, onMounted, reactive } from 'vue'
import china from './china.json'
import * as echarts from 'echarts'
let dataList = ref([])
const mapEcharts = () => {
    let initMap = echarts.init(document.querySelector('#mapDom'))
    echarts.registerMap('china', china);
    let options = {
        title: {
            // text: '中国地图',
            // sublink:
            //     'http://zh.wikipedia.org/wiki/%E9%A6%99%E6%B8%AF%E8%A1%8C%E6%94%BF%E5%8D%80%E5%8A%83#cite_note-12'
        },
        legend: {
            show: false
        },
        // tooltip: {
        //     trigger: 'item',
        //     formatter: '{b}<br/>{c} (销量)'
        // },
        // toolbox: {
        //     show: true,
        //     orient: 'vertical',
        //     left: 'right',
        //     top: 'center',
        //     feature: {
        //         dataView: { readOnly: false },
        //         restore: {},
        //         saveAsImage: {}
        //     }
        // },
        visualMap: {
            show: false,
            min: 0,
            max: 1000,
            text: ['High', 'Low'],
            realtime: false,
            calculable: true,
            inRange: {
                color: ['lightskyblue', 'yellow', 'orangered']
            }
        },
        series: [
            {
                name: '中国',
                type: 'map',
                map: 'china',
                label: {
                    show: false
                },
                data: dataList.value,
            }
        ]
    }
    initMap.setOption(options)
    window.addEventListener("resize", function () {
        myChart.resize();
    });

}
onMounted(() => {
    dataList.value = [
        { name: '北京市', value: 2901 },
        { name: '天津市', value: 3201 },
        { name: '河北省', value: 2402 },
        { name: '山西省', value: 5203 },
        { name: '内蒙古自治区', value: 1201 },
        { name: '辽宁省', value: 2105 },
        { name: '吉林省', value: 6202 },
        { name: '黑龙江省', value: 2034 },
        { name: '上海市', value: 2201 },
        { name: '江苏省', value: 8202 },
        { name: '浙江省', value: 3205 },
        { name: '安徽省', value: 5201 },

    ]

    dataListDsc.value = dataList.value.splice(0, 10)


    nextTick(() => {
        mapEcharts()
    })
})

// 表格数据
let dataListDsc = ref([])
</script>
<style lang="less" scoped>
.echarts_box {
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 6px 10px 0px #E5ECFF;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .echart {
        width: 60%;
        height: 100%;

    }

    .map_dsc {
        width: 40%;
        height: 100%;
        overflow: auto;
        border-radius: 8px;
        background: rgba(245, 249, 255, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .map_dsc_title {
            color: rgba(46, 50, 56, 1);
            font-family: PingFang SC;
            font-weight: 700;
            font-size: 14px;
            line-height: 32px;
            letter-spacing: 0px;
            width: 85%;
            text-align: left;
        }

        // padding: 16px 16px;
        .tabledata {
            width: 90%;
            height: 90%;
            background: rgba(245, 249, 255, 1);





        }

        /deep/ .el-table .el-table__cell {
            padding: 0 !important;
        }
    }
}
</style>